import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"

// Import styles
import "../styles/blog-post.scss"
import { PageHeader } from "../components"

const Page = ({ data, location }) => {
  const page = data.ghostPage
  const hasHero = !!page.tags?.find(
    tag => tag.slug === "hash-templatepagewithhero"
  )

  return (
    <Layout>
      <MetaData data={data} location={location} type="website" />

      {/* Add codeinjection scripts */}
      <div
        className="load-external-scripts"
        dangerouslySetInnerHTML={{ __html: page.codeinjection_head }}
      ></div>

      {hasHero && <PageHeader title={page.title} />}

      <div className="post-view">
        <article
          className={
            "wrapper-800" +
            (page.feature_image == null ? " no-feature-image" : "")
          }
        >
          {!hasHero && (
            <header className="post__header">
              <h1 className="post__title">{page.title}</h1>
              {page.feature_image && (
                <figure className="post__feature-image">
                  <img src={page.feature_image} alt={page.title} />
                </figure>
              )}
            </header>
          )}

          {/* Post Content */}
          <div className="post__body">
            <div
              className="post-full-content load-external-scripts"
              dangerouslySetInnerHTML={{ __html: page.html }}
            ></div>
          </div>
        </article>

        <div
          className="load-external-scripts"
          dangerouslySetInnerHTML={{ __html: page.codeinjection_foot }}
        ></div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      ...GhostPageFields
    }
  }
`
